<template>
	<v-sheet>
		<v-text-field v-model="search"></v-text-field>
		<v-data-table :items="appointmentParticipants" :headers="headers">
		</v-data-table>
		<v-btn @click="go">GO</v-btn>
	</v-sheet>
</template>

<script>
	export default {
		name: "test",
		data: () => {
			return {
				search: null,
				selected: []
			};
		},
		computed: {
			appointmentParticipants() {
				return this.$store.getters.getByKey(
					"appointmentParticipants",
					"payment_status",
					"pending"
				);
			},
			headers() {
				return [
					{text: "id", value: "id"},
					{text: "payment_status", value: "payment_status"}
				];
			}
		},
		methods: {
			go() {
				const self = this;
				
				self.appointmentParticipants.forEach((a) => {
					self.$store.dispatch("appointmentParticipants/patch", {
						id: a.id,
						payment_status: "problem"
					});
				});
			}
		},
		created() {
			this.$store.dispatch("appointmentParticipants/fetchAndAdd", {
				clauses: {where: [["payment_status", "==", "pending"]]}
			});
		}
	};
</script>
